/**
 * @generated SignedSource<<a9438d901a2c2da08225d60392447896>>
 * @relayHash e12a5cab1a321a304806f6b6727d95dd
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ea7b2b7f974c914de963e1099586c08d8cbcd9abd513bb2bb27122ff3627f891

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessBoardEmbedQuery } from './src_jiraBusinessBoardEmbedQuery.graphql';

import withCategoryField_provider from '@atlassian/jira-relay-provider/src/relay-category-field.relayprovider';

const node: PreloadableConcreteRequest<src_jiraBusinessBoardEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "ea7b2b7f974c914de963e1099586c08d8cbcd9abd513bb2bb27122ff3627f891",
    "metadata": {},
    "name": "src_jiraBusinessBoardEmbedQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaycategoryfieldrelayprovider": withCategoryField_provider
    }
  }
};

export default node;
