/**
 * @generated SignedSource<<d031b4a74cee16016e1f900d19a4c8cd>>
 * @relayHash 41dc67d928f881da43506a9ef2b27136
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 5c00a4541ed1add20cce9f61691a15f82858603b1d2d0cb3a7f565d59e1843a7

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessBoardQuery } from './src_jiraBusinessBoardQuery.graphql';

import withCategoryField_provider from '@atlassian/jira-relay-provider/src/relay-category-field.relayprovider';

const node: PreloadableConcreteRequest<src_jiraBusinessBoardQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "5c00a4541ed1add20cce9f61691a15f82858603b1d2d0cb3a7f565d59e1843a7",
    "metadata": {},
    "name": "src_jiraBusinessBoardQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaycategoryfieldrelayprovider": withCategoryField_provider
    }
  }
};

export default node;
