export const TAB_UNDERLINE_HEIGHT_OLD = 2;
export const TAB_UNDERLINE_HEIGHT = 3;
export const TAB_HEIGHT = 32;
export const TAB_ANGLE = 5;
export const TAB_GAP_OLD = 12;
export const TAB_GAP = 4

/**
 * We show a count of the overflowing tabs (tabs in the more menu)
 * This number is the maximum count we will show. If there are more tabs, we show "<MAX_MORE_TAB_COUNT>+" e.g. "9+"
 * Note: If this number changed to two-digits, the CSS in navigation-kit-ui-tab-list may need to be updated, as this would affect the width calculation of the More menu
 */
export const MAX_MORE_TAB_COUNT = 9;

export const MORE_TRIGGER_ID = 'MORE';

//  For product-level custom theming until there is a more robust solution
export const UNSAFE_HORIZONTAL_NAVIGATION_COLOR_BACKGROUND_NEUTRAL_HOVERED_VAR =
	'--horizontal-navigation-color-background-neutral-hovered';
export const UNSAFE_HORIZONTAL_NAVIGATION_COLOR_LINK_VAR = '--horizontal-navigation-color-link';
export const UNSAFE_HORIZONTAL_NAVIGATION_COLOR_LINK_PRESSED_VAR =
	'--horizontal-navigation-color-link-pressed';
export const UNSAFE_HORIZONTAL_NAVIGATION_COLOR_TEXT_SUBTLE_VAR =
	'--horizontal-navigation-color-text-subtle';
export const UNSAFE_HORIZONTAL_NAVIGATION_COLOR_TEXT_VAR = '--horizontal-navigation-color-text';
export const UNSAFE_HORIZONTAL_NAVIGATION_COLOR_ELEVATION_SURFACE_VAR =
	'--horizontal-navigation-color-elevation-surface';
export const UNSAFE_HORIZONTAL_NAVIGATION_COLOR_BORDER_SELECTED_VAR =
	'--horizontal-navigation-color-border-selected';
export const UNSAFE_HORIZONTAL_NAVIGATION_COLOR_BORDER_VAR = '--horizontal-navigation-color-border';
export const UNSAFE_HORIZONTAL_NAVIGATION_COLOR_BACKGROUND_NEUTRAL_VAR =
	'--horizontal-navigation-color-background-neutral';

export const UNSAFE_INTERNAL_MORE_COLOR_VAR = '--hn-yjlbv';
